import React, {Component} from "react"
import {Link, graphql} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class ProductsPage extends Component {
  render() {
    const products = this.props.data.allWcProducts.edges
    return (
      <Layout>
        <SEO title="ProductsPage"/>
        <div className="section">
          <div className="container">
            <p className="title">SHOP</p>
          </div>
        </div>
        <section className="section">
          <div className="container">
          <div className="columns is-multiline">

          {products.map(({node}) => (
            <div className="column is-4" key={node.id}>
            <Link to={`/products/${node.slug}`} css={{
              textDecoration: `none`
            }}>

                <h1 className="title">{node.name}</h1>

              </Link>

            </div>
          ))}
          </div>

          </div>
        </section>
      </Layout>
    )
  }
}


export default ProductsPage

// Set here the ID of the home page.
export const pageQuery = graphql `
  query {
    allWcProducts(filter: {catalog_visibility: {eq: "visible"}, status: {eq: "publish"}, tags: {elemMatch: {name: {eq: "CBD"}}}}) {
      edges {
        node {
          slug
          name
        }
      }
    }
  }
`
